import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
    Text,
    Flex,
    Center,
    Box,
    VStack,
    StackDivider,
    Heading
} from '@chakra-ui/react'

import Layout from "../components/layouts/layout"
import Seo from "../components/utils/seo"
/* import HomeHero from "../components/sections/homeHero"
import Services1 from "../components/sections/services1"
import Services2 from "../components/sections/services2"
import Services3 from "../components/sections/services3"
import Cta from "../components/sections/cta" */




const IndexPage = () => (

    <Layout>
        <Seo title="Inicio" />


        <Flex mt={8} width={"100vw"} alignContent={"center"} justifyContent={"center"}>
            <Center>
                <VStack
                    divider={<StackDivider borderColor='gray.200' />}
                    spacing={4}
                    align='stretch'
                >
                    <Box width='20rem'>
                        <StaticImage
                            src="../images/smiledesigner-970x259.png"
                            alt="Smile Designer logo"
                        />

                    </Box>
                    <Center>
                        <Heading fontSize='3xl'>Dr. Carrillo Haack</Heading>
                    </Center>
                    <Box>
                        <Text fontSize='md'>Especialista en Odontología Estética avanzada | 😀 Te Enseñamos a Sonreír.</Text>
                    </Box>
                    <Box>
                        <Text fontSize='md'>Centro San Ignacio - Tel: 0412 991 77 53</Text>
                    </Box>
                    <Center padding='2rem' bgColor='#fddd9b'>
                        <Text fontSize='md'>NOTA: Sitio web en construcción</Text>
                    </Center>
                </VStack>
            </Center>
        </Flex>


        {/*   <HomeHero />
        <Services1 />
        <Services2 />
        <Services3 />
        <Cta /> */}
    </Layout>

)

export default IndexPage